import { useContext } from 'react';
import { Button, Hint, Icon, InlineSpace, Strong } from '@smart-kasa/ui';

import { HelpDeskContext } from 'context/HelpDeskContext';

import { Massenger } from '../Massenger';
import styles from './SupportDock.module.scss';

const { REACT_APP_TELEGRAM_BOT, REACT_APP_VIBER_BOT } = process.env;

export const SupportDock = () => {
  const { openChat } = useContext(HelpDeskContext);

  return (
    <div className={styles.support}>
      <Hint margin={0} className={styles.phone}>
        <Strong>тел. 0 800 312 371</Strong>
      </Hint>
      <InlineSpace />
      {REACT_APP_TELEGRAM_BOT && <Massenger type="telegram" url={REACT_APP_TELEGRAM_BOT} />}
      <InlineSpace />
      {REACT_APP_VIBER_BOT && <Massenger type="viber" url={REACT_APP_VIBER_BOT} />}
      <InlineSpace />
      <Button
        className={styles.chat}
        rightIcon={<Icon name="support" color="light" />}
        onClick={openChat}
      >
        <span className={styles.chatText}>Online-підтримка</span>
      </Button>
    </div>
  );
};
