import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, DataTable, IDataTableColumn, Page, Pagination, Preloader } from '@smart-kasa/ui';

import { usePagination } from 'hooks';
import { InlineLink } from 'components';
import { TAX_GROUP_MAPPING } from 'constants/taxGroup';
import { CLASSIFIER_TYPE_MAPPING } from 'constants/classifierType';
import { useFindProductImportDocumentQuery } from 'services/api/core/inventory/api';
import type { IImportRow } from 'services/api/core/inventory/types';

import styles from './ImportCatalogShow.module.scss';

export const ImportCatalogShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { page, perPage, onPageChange } = usePagination();
  const { data: file, isLoading } = useFindProductImportDocumentQuery(Number(id));
  const { results } = file?.data || {};
  const columns: IDataTableColumn<IImportRow>[] = [
    {
      key: 'row',
      title: 'Рядок',
    },
    {
      key: 'attributes',
      title: 'Код',
      render: ({ remoteId }, { errors }) => (
        <div>
          <div>{remoteId}</div>
          <div className={styles.error}>{errors['remoteId']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Штрихкод',
      render: ({ number }, { errors }) => (
        <div>
          <div>{number}</div>
          <div className={styles.error}>{errors['number']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Арктикул',
      render: ({ alterNumber }, { errors }) => (
        <div>
          <div>{alterNumber}</div>
          <div className={styles.error}>{errors['alterNumber']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Назва',
      render: ({ title }, { errors }) => (
        <div>
          <div>{title}</div>
          <div className={styles.error}>{errors['title']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Коротка назва',
      render: ({ alterTitle }, { errors }) => (
        <div>
          <div>{alterTitle}</div>
          <div className={styles.error}>{errors['alterTitle']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Од. виміру',
      render: ({ unitTypeName }, { errors }) => (
        <div>
          <div>{unitTypeName}</div>
          <div className={styles.error}>{errors['unitTypeId']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Ціна',
      render: ({ price }, { errors }) => (
        <div>
          <div>{price}</div>
          <div className={styles.error}>{errors['price']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Гр. оподаткування',
      render: ({ taxGroupId }, { errors }) => {
        const taxGroup = TAX_GROUP_MAPPING[taxGroupId];

        return (
          <div>
            <div>{taxGroup ? taxGroup.label : '-'}</div>
            <div className={styles.error}>{errors['taxGroupId']}</div>
          </div>
        );
      },
    },
    {
      key: 'attributes',
      title: 'Підгрупа',
      render: ({ subgroupId }, { errors }) => (
        <div>
          <div>{subgroupId}</div>
          <div className={styles.error}>{errors['subgroupId']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Класифікатор',
      render: ({ classifierTypeId }, { errors }) => {
        const classifierType = CLASSIFIER_TYPE_MAPPING[classifierTypeId];

        return (
          <div>
            <div>{classifierType ? classifierType.label : '-'}</div>
            <div className={styles.error}>{errors['classifierTypeId']}</div>
          </div>
        );
      },
    },
    {
      key: 'attributes',
      title: 'Код класифікатора',
      render: ({ classifierCode }, { errors }) => (
        <div>
          <div>{classifierCode}</div>
          <div className={styles.error}>{errors['classifierCode']}</div>
        </div>
      ),
    },
    {
      key: 'attributes',
      title: 'Вільна ціна',
      render: ({ isFreePrice }, { errors }) => (
        <div>
          <div>{isFreePrice ? 'Так' : 'Ні'}</div>
          <div className={styles.error}>{errors['isFreePrice']}</div>
        </div>
      ),
    },
  ];

  const getPaginatedData = (data) => {
    const startIndex = page * perPage - perPage;
    const endIndex = startIndex + perPage;

    return data.slice(startIndex, endIndex);
  };

  if (isLoading) return <Preloader />;

  return (
    <Page
      title={
        <InlineLink icon="arrowLeft" onClick={() => navigate(-1)}>
          Документ №{id}
        </InlineLink>
      }
    >
      {results && (
        <Fragment>
          {!results.error && results.invalid.length === 0 && (
            <Alert type="accent" icon="check">
              Файл імпортовано успішно
            </Alert>
          )}

          {results.error && (
            <Alert type="danger" icon="close">
              Товари з файлу не було імпортовано. Перевірте відповідність даних згідно
              файлу-шаблона.
            </Alert>
          )}

          {results.invalid && results.invalid.length > 0 && (
            <Fragment>
              <Alert type="danger" icon="info">
                Виникли помилки під час імпорту товарів
              </Alert>
              <DataTable<IImportRow, 'row'>
                rowIdKey="row"
                data={getPaginatedData(results.invalid)}
                columns={columns}
                className={styles.table}
              />

              <Pagination
                totalItems={results.invalid.length}
                currentPage={page}
                perPage={perPage}
                onPageChange={onPageChange}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Page>
  );
};
