import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useScript } from 'hooks/useScript';
import { SessionContext } from 'context/SessionContext';
import { TranslationContext } from 'context/TranslationContext';

import Avatar from 'assets/chatbot/avatar.jpg';
import Logo from 'assets/chatbot/logo.jpg';

interface IHelpDeskContext {
  initialized: boolean;
  openChat: () => void;
  closeChat: () => void;
}

export const HelpDeskContext = React.createContext<IHelpDeskContext>({
  initialized: false,
  openChat: () => {},
  closeChat: () => {},
});

export const HelpDeskContextProvider: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const { locale } = useContext(TranslationContext);
  const { isLogged } = useContext(SessionContext);
  const { isSuccessful: isScriptLoaded } = useScript('https://widget-smartkasa.kwizbot.io/kwjs.js');

  const kwizbot = (action, options = {}) => {
    window.kw = window.kw || {};
    window.kw.q = window.kw.q || [];
    window.kw.q.push([action, options]);
  };

  kwizbot('init', {
    language_code: locale,
    bot_id: '3',
    title: formatMessage({ id: 'chat.title' }),
    subtitle: formatMessage({ id: 'chat.subtitle' }),
    titleImageUrl: Logo,
    botAvatarImageUrl: Avatar,
    launcherIsHiddenState: isLogged,
  });

  const dispatchEvent = (event: string, payload: any) =>
    window.kw_event && window.kw_event(event, payload);
  const openChat = () => dispatchEvent('openchat', true);
  const closeChat = () => dispatchEvent('openchat', false);

  useEffect(() => {
    dispatchEvent('kwreinitwidget', { launcherIsHiddenState: isLogged, locale });
  }, [isLogged, locale]);

  return (
    <HelpDeskContext.Provider value={{ initialized: isScriptLoaded, openChat, closeChat }}>
      <div id="kwizbot_widget"></div>

      {children}
    </HelpDeskContext.Provider>
  );
};
